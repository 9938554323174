import React, { useEffect, useState } from 'react'
import { Navbar, Container, Nav } from 'react-bootstrap'
import logo from "../images/logo-horizontal.jpg"

const Header = () => {
  const [path, setPath] = useState({
    path: "/"
  })

  useEffect(() => {
    setPath(window.location.pathname)
  }, [])

  return (
    <header>
      <Navbar expand="lg" sticky="top">
        <Container
         style={{
          margin: `0 auto`,
          padding: `0 1.0875rem 1.45rem`,
        }}>
          <Navbar.Brand href="/">
            <img src={logo} alt="Doces D'Arada" height="50" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="justify-content-end" style={{ width: "100%" }} activeKey={path}>
              <Nav.Link href="/produtos/">Produtos</Nav.Link>
              <Nav.Link href="/lojas/">Onde comprar</Nav.Link>
              <Nav.Link href="/contactos/">Contactos</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  )
}

export default Header
