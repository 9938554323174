import * as React from "react"
import PropTypes from "prop-types"
import Header from "./header"
import "bootstrap/dist/css/bootstrap.min.css"
import "font-awesome/css/font-awesome.min.css"
import "./layout.css"
import { Container, Navbar, Nav } from 'react-bootstrap';

const Layout = ({ children }) => {

  return (
    <>
      <Header />
      <div
        style={{
          margin: `0 auto`,
          padding: `0 1.0875rem 1.45rem`,
        }}
      >
        <Container>
          <main>{children}</main>
        </Container>
        <footer>
          <Navbar className="d-none d-sm-block" fixed="bottom">
            <Nav className="justify-content-center" style={{ marginRight: '2rem', width: "100%" }}>
              <Nav.Item>© {"Doces D'Arada"} {new Date().getFullYear()}</Nav.Item>
            </Nav>
          </Navbar>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
